import React, { Component, ReactNode } from 'react';
import { css } from 'glamor';
import { getChildren, injectProps } from './Shared';
import Rsp, { onDefault } from './Rsp';
import X from './X';

export default class Y extends Component<{
    style?: Record<string, any>;
    h?: number;
    align?: 'left' | 'center' | 'right' | 'space-evenly' | 'space-between';
    children?: any
}> {
    render() {
        const left: boolean = this.props.align && this.props.align === 'left' ? true : false
        return (
            <Rsp
                styles={{
                    [onDefault]: {
                        position: 'relative',
                        width: '100%',
                        height: this.props.h ? this.props.h : '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        ...this.props.style
                    }
                }}
            >   
                { !left && <>{this.props.children}</>}
                { left && 
                    <div style={{ width : '100%'}}>
                        {this.props.children}
                    </div>
                }
            </Rsp>
        );
    }
}
