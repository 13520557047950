import React, { Component } from 'react';
import Rsp, { onDefault } from './Rsp';
import { ifProp } from './Shared';


//[UPDATE] added offsetX property
//[UPDATE] remove color: white
//[UPDATE] added alignment

export type Alignment = 'space-evenly' | 'space-between' | 'center' | 'left' | 'right';

export default class X extends Component<{
    offsetX?: number | string
    style?: Record<string, any>;
    align?: Alignment;
    children?: any
}> {
    render() {
        let offsetX : string | number = ifProp(this.props.offsetX, undefined )
        if( typeof offsetX === 'number'){
            offsetX = offsetX + "px"
        }
            

        return (
            <Rsp
                styles={{
                    [onDefault]: {
                        position: 'relative',
                        width: `calc(100% - ${offsetX})`,
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: this.props.align
                            ? this.props.align
                            : 'center',
                        ...this.props.style
                    }
                }}
            >
                {this.props.children}
            </Rsp>
        );
    }
}
